@import "../../../styles/base/abstracts";

.hbs-program-comparison-tray {
  padding: 0 !important;
}

.hbs-program-comparison-tray__fab {
  @include fixed(x $spacing-md $spacing-md x);
  @include size(55px);
  background-color: $c-spot;
  border-radius: 100px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  color: $c-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z-index-1;
  transition: $transition;
  opacity: 1;

  &:focus-visible {
    outline-offset: 3px;
  }

  &:hover {
    .hbs-program-comparison-tray__fab-icon {
      .left-arrow {
        transform: translateX(5%);
      }

      .right-arrow {
        transform: translateX(-5%);
      }
    }
  }

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.hbs-program-comparison-tray__fab-icon {
  @include size(50%);

  .left-arrow {
    transition: $transition;
  }

  .right-arrow {
    transition: $transition;
  }
}

.hbs-program-comparison-tray__inner {
  @include fixed(x 0 0 0);
  @include grid-parent;
  justify-content: space-between;
  background-color: $c-bg-complementary;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);
  transition: $transition;
  width: 100%;
  z-index: $z-index-2;
  padding-top: $spacing-xl + $spacing-sm;
  padding-bottom: $spacing-md;
  display: flex;
  flex-direction: column;

  .hbs-program-comparison-tray--online & {
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.08);
  }

  @include mq($bp-desktop) {
    flex-direction: row;
    padding-top: $spacing-md;
    align-items: flex-end;
  }
}

.hbs-program-comparison-tray__inner--hidden {
  transform: translateY(100%);
  visibility: hidden;
}

.hbs-program-comparison-tray__hide {
  @include xs-title;
  @include absolute($spacing-xs $spacing-xs x x);
  display: flex;
  align-items: center;

  .hbs-icon {
    @include size($icon-size-sm);
    margin-left: $spacing-xs;
  }

  @include mq($bp-desktop) {
    @include absolute($spacing-md 2vw x x);
  }
}

.hbs-program-comparison-tray__track {
  @include grid-child;
  width: 100%;
  position: relative;

  @include mq($bp-desktop) {
    width: $col-12;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: $grid-padding-mobile;
    width: $grid-padding-mobile;
    height: 100%;
    background: linear-gradient(to left, $c-bg-complementary, transparent);
    z-index: 1000;

    @include mq($bp-tablet) {
      right: $grid-padding;
      width: $grid-padding;
    }
  }
}

.hbs-program-comparison-tray__list {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: $spacing-sm;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: $grid-padding-mobile;

  @include mq($bp-tablet) {
    padding-right: $grid-padding;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.hbs-program-comparison-tray__item {
  @include padding($spacing-sm $spacing-xl $spacing-sm $spacing-sm);
  width: 160px;
  min-width: 160px;
  min-height: 6em;
  position: relative;
  background-color: $c-bg;
  display: flex;
  align-items: flex-end;

  @include mq($bp-desktop) {
    width: 200px;
    min-width: 200px;
    height: 8em;
  }
}

.hbs-program-comparison-tray__item-title {
  @include small-title;
  line-height: $lh-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;

  @include mq($bp-desktop) {
    @include small-title;
    line-height: $lh-sm;
  }
}

.hbs-program-comparison-tray__remove {
  @include size($icon-size-sm);
  position: absolute;
  top: $spacing-sm;
  right: $spacing-sm;
  transition: $transition;

  .hbs-icon {
    margin-top: -18px;
  }

  &:hover {
    color: $c-black-3;
  }
}

.hbs-program-comparison-tray__actions {
  @include grid-child;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  margin-top: $spacing-lg;

  @include mq($bp-desktop) {
    width: $col-3;
    margin-left: $col-1;
  }
}

.hbs-program-comparison-tray__compare {
  justify-content: center;
}

.hbs-program-comparison-tray__compare--disabled {
  background-color: $c-bg;
  color: $c-text-lighter;
  pointer-events: none;
}

.hbs-program-comparison-tray__download {
  justify-content: center;
}
